import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiOutlineMail, AiFillInstagram } from "react-icons/ai";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h3>In Home Piano</h3>
        </Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © 2014 - {year}</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="/"
                style={{ color: "#8195df" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="mailto:info@inhomepiano.com"
                style={{ color: "#8195df" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineMail />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
