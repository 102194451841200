import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "left" }}>
            Hi Everyone, I'm <span className="blue">Jennifer</span> located in
            Toronto, Ontario and I am willing to travel within the
            <span className="blue"> GTA area. </span>
            Originally from Windsor and with over 25 years piano playing
            experience, Jennifer attended the University of Windsor Music
            program and is a student of the Royal Conservatory of Music (RCM)
            where she is currently level 10. Jennifer believes in building a
            rapport with her students with a focus on adapting to each and every
            student in order to understand their abilities, goals and
            limitations. Many students of hers have gone on to pass exams at the
            RCM and perform in music festivals and recitals across the GTA. Her
            philosophy lies in promoting personal growth and pushing boundaries
            comfortably so that students can be the absolute best version of
            themselves. Performances and recital engagements have brought
            Jennifer to the likes of church events, anniversaries, and baptisms.
            She plays high-end background music in a variety of musical styles
            for wedding ceremonies, receptions, parties and corporate functions.
            If you are interested in booking an event, please contact Jennifer
            by email at jennifer@inhomepiano.com to discuss your request.
          </p>

          <p style={{ color: "#032cbf" }}>"WHERE WORDS FAIL, MUSIC SPEAKS" </p>
          <footer className="blockquote-footer">HANS CHRISTIAN ANDERSEN</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
