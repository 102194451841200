import { useForm } from "react-hook-form";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import emailjs from "@emailjs/browser";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from "react-router-dom";

export default function ContactForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const serviceID = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;

  const onSubmit = (data) => {
    console.log(data);
    emailjs
      .send(`${serviceID}`, `${templateId}`, data, {
        publicKey: `${publicKey}`,
      })
      .then(
        () => {
          console.log("SUCCESS!");
          navigate("/", { replace: true });
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <Container fluid className="project-section">
      <Container>
        <Row>
          <h1 style={{ fontSize: "2.6em" }}>Contact & Booking</h1>{" "}
          <Col md="8">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                className="form-control mb-3"
                placeholder="Name"
                {...register("Name", { required: "Name is required." })}
              />
              <ErrorMessage
                errors={errors}
                name="Name"
                render={({ message }) => <p className="error">{message}</p>}
              />
              <input
                className="form-control mb-3"
                placeholder="Email"
                {...register("Email", { required: "Email is required." })}
              />
              <ErrorMessage
                errors={errors}
                name="Email"
                render={({ message }) => <p className="error">{message}</p>}
              />
              <input
                className="form-control mb-3"
                placeholder="Phone"
                {...register("Phone", {
                  required: "10-digit Phone Number required.",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="Phone"
                render={({ message }) => <p className="error">{message}</p>}
              />
              <label htmlFor="studentType" className="visually-hidden">
                Child Or Adult
              </label>
              <select
                id="studentType"
                className="form-select mb-3"
                {...register("studentType", {
                  required: "Select a student type.",
                })}
              >
                <option defaultValue={""} value="">
                  --Please Select--
                </option>
                <option defaultValue={"child"} value="child">
                  Child
                </option>
                <option defaultValue={"adult"} value="adult">
                  adult
                </option>
              </select>
              <ErrorMessage
                errors={errors}
                name="studentType"
                render={({ message }) => <p className="error">{message}</p>}
              />
              <label htmlFor="Location" className="visually-hidden">
                Lesson Type
              </label>

              <select
                id="Location"
                className="form-control mb-3"
                {...register("Location", { required: "Select a location." })}
              >
                <option defaultValue={""} value="">
                  --Please Select--
                </option>
                <option value="Jennifer's Home Studio">
                  Jennifer's Home Studio
                </option>
                <option value="StudentsHome">Your Home</option>
                <option value="Online">Online</option>
              </select>
              <ErrorMessage
                errors={errors}
                name="Location"
                render={({ message }) => <p className="error">{message}</p>}
              />
              <textarea
                placeholder="Message"
                defaultValue={""}
                className="form-control mb-3"
                {...register("Message")}
                rows="4"
                cols="50"
              />
              <input type="submit" className="btn btn-primary mb-2" />
            </form>
          </Col>
        </Row>
        <Row>
          <Col md={7} className="home-about-description">
            <p className="home-about-body">
              For lessons, bookings and other inquiries, contact Jennifer
              directly at info@inhomepiano.com or by using the form above.
              Please provide as much detail as possible. You will receive a
              response within two business days.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
