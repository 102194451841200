import React from "react";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Home2 from "./Home2";
import { Link } from "react-router-dom";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <strong className="main-name">
                <h1 className="heading-name">Piano Lessons</h1>
                <h1 className="heading-name"> for All Ages and Levels</h1>
              </strong>
              <div style={{ padding: 50, textAlign: "left" }}>
                <Button as={Link} to="/contact" className="cTAButton">
                  Get Started
                </Button>
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }} className="homeCol2">
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "300px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
