import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import online from "../../Assets/Projects/pic03.jpg";
import theirHome from "../../Assets/Projects/pic02.jpg";
import home from "../../Assets/Projects/pic01.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          Piano <strong className="blue">Lessons </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={home}
              isBlog={false}
              title="My Home Studio"
              description="Piano lessons can be offered at Jennifer's private residence. Her home is a comfortable, relaxed, and friendly atmosphere, away from any distractions there could be at a student's home. Students learn on Jennifer's high-quality piano with access to her library of sheet music and books. Jennifer is located in the Forest Hill area of Toronto."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={theirHome}
              isBlog={false}
              title="Your Home"
              description="Students can save time and energy by having Jennifer travel to their home for a piano lesson. For in home piano lessons, all that is required is a full 88 key piano (or keyboard) and a quiet space to have the lesson that is free from noise and distractions. Lessons are offered in the Greater Toronto Area."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={online}
              isBlog={false}
              title="Online"
              description="Jennifer offers live video chat lessons using Zoom. One-on-one online lessons with an instructor are proven to be a very effective way to learn. Students only need basic equipment that they most likely already have. When in-person lessons aren't possible, this is a great alternative!"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
