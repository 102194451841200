import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/jennifer.png";
import { AiFillInstagram, AiOutlineMail } from "react-icons/ai";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row className="gx-5">
          <Col md={5} className="myAvtar">
            <h1 style={{ fontSize: "2.6em" }}>
              THE <span className="blue"> PIANO TEACHER </span>
            </h1>
            <img
              src={myImg}
              className="img-fluid"
              alt="avatar"
              style={{ width: "100%" }}
            />
          </Col>
          <Col md={7} className="home-about-description">
            <p className="home-about-body">
              Jennifer strives to provide a comprehensive{" "}
              <b className="blue">music education</b>, while encouraging the
              students' personal interests. She has a patient, individual
              needs-based approach, and makes learning music fun and motivating.
              <br />
              <br />
              Her outlook is that music is meant to express oneself and be
              enjoyed, first and foremost. Instilling a curiosity, sense of
              ownership and love of music in her students is her goal. She
              welcomes students of all ages, backgrounds and skill levels.
              Jennifer simplifies the learning experience and teaches techniques
              in a fun and engaging way. She has been teaching{" "}
              <b className="blue">beginner to intermediate</b> piano to{" "}
              <b className="blue">children, teens, adults and seniors</b> for
              over 10 years.
              <br />
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>CONNECT</h1>
            <p>
              Feel free to <span className="blue">connect </span>with Jennifer
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:info@inhomepiano.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineMail />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
