import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aboutcard from "./AboutCard";
import myImg from "../../Assets/jennifer.png";
import recital from "../../Assets/recital.jpg";
import studentPic01 from "../../Assets/JmJnkPO5.jpg";
import studentPic02 from "../../Assets/b4ao_1Vn.jpg";
import AboutPicCard from "./AboutPicCard";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img
              src={myImg}
              className="img-fluid"
              alt="avatar"
              style={{ width: "100%" }}
            />
          </Col>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Know Who <strong className="blue">I AM</strong>
            </h1>
            <Aboutcard />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={12} style={{ paddingBottom: "50px" }}>
            <h1 className="project-heading">
              End Of Year <strong className="blue">Recital </strong>
            </h1>
          </Col>
          <Col md={12} style={{ paddingBottom: "50px" }}>
            <AboutPicCard
              imgPath={recital}
              isBlog={false}
              title="My Annual Student Recital (2024)"
              description=""
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={12} style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <h1 className="project-heading">
              Student <strong className="blue">Achievements</strong>
            </h1>
          </Col>
          <Col md={6} style={{ paddingBottom: "50px" }}>
            <AboutPicCard
              imgPath={studentPic02}
              isBlog={false}
              title="2023 North York Music Festival"
              description=""
            />
          </Col>
          <Col md={6} style={{ paddingBottom: "50px" }}>
            <AboutPicCard
              imgPath={studentPic01}
              isBlog={false}
              title="2024 North York Music Festival"
              description=""
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
